// export function accessibleRouteChangeHandler() {
//   return window.setTimeout(() => {
//     const mainContainer = document.getElementById('primary-app-container');
//     if (mainContainer) {
//       mainContainer.focus();
//     }
//   }, 50);
// }




export const wait = (mm)=>{
  return new Promise<void>((resolve)=>{
    setTimeout(()=>{
      resolve();
    },mm);
  })
}


export const formatEur = (value:number|string)=>{
    const v = value || "0";
    return v.toLocaleString('it-IT', {
      style: 'currency',
      currency: 'EUR'
    });


}