/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-direct-mutation-state */
import * as React from 'react'
import { Form } from '@patternfly/react-core'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericInput from 'src/frontend/app/components/GenericInput'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import GenericCard from 'src/backoffice/app/components/GenericCard'
import GenericCheckbox from 'src/backoffice/app/components/GenericCheckbox'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import ListaAllegatiAssicurazioni from 'src/backoffice/app/components/Assicurazioni/ListaAllegatiAssicurazioni'
import { AppUtils as U } from 'src/app/utils/FormUtils'
import { getStudioAssociatoById } from 'src/processes/StudioAssociato'
import { TestiLabel } from 'src/processes/assicurazioni/CostantiPolizze'
import { AppCostants } from 'src/app/utils/AppConstant'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormDatiInformativi extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
          activeTab: props.tabNumber,
          tabClick: props.tabClick,
          prevData: props.prevData,
          prevClicked: props.prevClicked,
          polizza: props.data,
          getSelectValues:props.getSelectValues,
          fields: (this.props.data) ? this.props.data : {},
          errors: {},
          isLoaded : false,
          studioAssociatoFlag: false,
          congedoMaternitaPaternita: false,
          message: ''
        }
    }

    async componentDidMount(): Promise<void> {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

        if(this.props.data) {

            let obj = this.props.data;
            if (obj.assicurazioneAllegati){

                let allegati = obj.assicurazioneAllegati.sort( (p1, p2) => (p1.dataInserimento > p2.dataInserimento && p1.tipologiaAllegato === "polizze_dichiarazione_maternita_paternita") ? -1 : (p1.dataInserimento < p2.dataInserimento && p1.tipologiaAllegato === "polizze_dichiarazione_maternita_paternita") ? 1 : 0);
                allegati.map((fileAllegato) =>{
                    if(allegati !== undefined && allegati.length > 0 && fileAllegato.tipologiaAllegato === "polizze_dichiarazione_maternita_paternita"){
                        this.setState({fileExists : true})
                    }
                })
            }

            if(this.props.prevData){
                //al precedente
                obj = this.props.prevData;
            }

            //casting da numerale a dropdown
            obj.operaStudioAssociato = U.dn(obj.operaStudioAssociato);
            obj.altrePolizze = U.dn(obj.altrePolizze);
            obj.maternita = U.dn(obj.maternita);
            obj.paternita = U.dn(obj.paternita);
            obj.sinistri = U.dn(obj.sinistri);
            obj.sinistriRicevutoLiberatoriaCompagnia = U.dn(obj.sinistriRicevutoLiberatoriaCompagnia);
            obj.danniPatrimoniali = U.dn(obj.danniPatrimoniali);
            obj.retroattivitaIllimitata = U.dn(obj.retroattivitaIllimitata);
            obj.coperturaPostuma = U.dn(obj.coperturaPostuma);
            if(typeof(obj.categoriaCalculated) !== 'object') obj.underTreAnniPrimaIscrizione = {label: (obj.categoriaCalculated === 2) ? 'Si' : 'No', value: (obj.categoriaCalculated === 2) ? 'Si' : 'No'};
            if(typeof(obj.idCategoriaDue) !== 'object') obj.idCategoriaDue = {label: (obj.categoria === 2) ? 'Si' : 'No', value: (obj.categoria === 2) ? 'Si' : 'No'};
            if(obj.categoriaCalculated === 2) obj.idCategoriaDue = {label: 'Si', value: 'Si'};
            if(typeof(obj.fileMaternitaPaternita)) obj.fileMaternitaPaternita = this.state.polizza.fileMaternitaPaternita;

            if (obj.idStudioAssociato && obj.idStudioAssociato !== null){
            await getStudioAssociatoById(obj.idStudioAssociato).then(async responseStudi => {
                let studioFound = "CF/P.IVA " + responseStudi.cfPiva + " - " + responseStudi.ragioneSociale;
                obj.studioAssociato = studioFound;
            }).catch((err => {
                this.setState({ listaStudiAssociati: []})
            }))
        }
            await this.setState({polizza : obj, isLoaded: true})
        }
    }

    closeModal = () => {
        this.setState({modalOpen:false});
    }

    showModal = async (booleanValue, title, text) => {
        await this.setState({
            modalOpen:booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
    }

    render() {
        const { tabNumber } = this.props;
        const keyData = TokenStore.getInstance().getDecodedTokenJWT();
        let fiscalNumber = keyData.fiscalNumber;
        let given_name = keyData.given_name;
        let family_name = keyData.family_name;
        let genere = keyData.spid_gender;
        this.state.polizza['nome'] = given_name;
        this.state.polizza['cognome'] = family_name;
        this.state.polizza['codiceFiscale'] = fiscalNumber;
        this.state.polizza['genere'] = genere;

        return (
                <div>
                    {this.state.isLoaded ?
                    <div>
                        <Form id="formPolizza" key={"formPolizzaVisualizza"} className="row" hidden={(!this.state.polizza)}>

                        <h2 className='stepTitle'>Dati informativi</h2>
                            <GenericCard className="cardStyle" key={'studioAssociato'} header="Studio Associato" body={
                                <div className="row">
                                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                                        <GenericInput isDisabled label='Selezione Studio Associato ' placeholder='Si / No'
                                            defaultValue={this.state.polizza.operaStudioAssociato ? this.state.polizza.operaStudioAssociato.label : '-'} errorMessageAlert={this.props.message} currentStep={tabNumber}
                                            />
                                    </div>
                                    <div hidden={this.state.polizza.operaStudioAssociato.label !== "Si"} className={isCurrentMobileDevice() ? null : 'col-5'}>
                                        <GenericInput isDisabled key={'idStudioAssociato'} label='Studio Associato' placeholder='Studio associato selezionato'
                                        defaultValue={(this.state.polizza.studioAssociato) ? this.state.polizza.studioAssociato : "-"}
                                        />
                                    </div>

                                </div>

                            }></GenericCard>
                            <GenericCard className="cardStyle" key={'altrePolizze'} header="Altre Polizze" body={
                                <div className="row">
                                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                                        <GenericInput isDisabled label='Possiede altre polizze' placeholder='Si / No'
                                            defaultValue={(this.state.polizza['altrePolizze']) ? this.state.polizza['altrePolizze'].label : '-'}  errorMessageAlert={this.props.message} currentStep={tabNumber}
                                            />
                                    </div>
                                </div>

                            }></GenericCard>

                            {/* visualizza la categoria due solo per le societa */}
                            { (this.state.polizza.tipoUtente === 'SC') === false &&
                                <>
                                    <GenericCard className="cardStyle" key={'categoriaDue'} header="Appartenenza alla Categoria 2" body={
                                        <div>
                                            <div className="row">
                                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                                    <GenericInput isDisabled label='Appartenenza alla categoria 2' placeholder='Si / No'
                                                        defaultValue={(this.state.polizza['idCategoriaDue']) ? this.state.polizza['idCategoriaDue'].label : '-'} errorMessageAlert={this.props.message} currentStep={tabNumber}
                                                        />
                                                </div>


                                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                                    <GenericInput isDisabled key={'underTreAnniPrimaIscrizione'} id="underTreAnniPrimaIscrizione" name={'underTreAnniPrimaIscrizione'} label="Meno di 35 anni e meno di 3 anni solari dalla prima iscrizione **" placeholder='No'
                                                    defaultValue={(this.state.polizza['underTreAnniPrimaIscrizione']) ? this.state.polizza['underTreAnniPrimaIscrizione'].label : 'No'} errorMessageAlert={this.props.message} currentStep={tabNumber}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                                    <GenericInput isDisabled label='Maternità ' placeholder='Si / No'
                                                    defaultValue={(this.state.polizza['maternita']) ? this.state.polizza['maternita'].label : 'No'} errorMessageAlert={this.props.message} currentStep={tabNumber}
                                                    />
                                                </div>
                                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                                    <GenericInput isDisabled label='Paternità ' placeholder='Si / No'
                                                        defaultValue={(this.state.polizza['paternita']) ? this.state.polizza['paternita'].label : 'No'} errorMessageAlert={this.props.message} currentStep={tabNumber}
                                                        />
                                                </div>
                                            </div>
                                            <div className="row" hidden={(this.state.polizza.maternita && this.state.polizza.maternita.label === 'No') && (this.state.polizza.paternita && this.state.polizza.paternita.label === 'No')}>
                                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                                <h6 key="foto">Certificato Maternità / Paternità</h6>
                                                </div>
                                                <div className="row">
                                                    <ListaAllegatiAssicurazioni
                                                        key={'allegatiAssicurazioni'}
                                                        polizza={this.state.polizza}
                                                        tipologiaAllegato={"polizze_dichiarazione_maternita_paternita"}
                                                        visualizzaFlag={this.props.visualizzaFlag}
                                                        note={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }></GenericCard>
                                </>
                            }

                            <GenericCard key={'dichiarazioni'} header="Dichiarazioni" body={
                                <div>
                                    {(this.state.polizza?.tipoRichiesta === "A") &&
                                    <>
                                        <div className="row">
                                            <div className={isCurrentMobileDevice() ? null : 'col-7'}>
                                                <GenericInput isDisabled key={'sinistri'} id="sinistri" label="Dichiarazione richieste sinistro *" placeholder='Si / No'
                                                    defaultValue={(this.state.polizza.sinistri) ? this.state.polizza.sinistri.label : '-'}
                                                />
                                            </div>
                                        </div>

                                        { this.state.polizza['sinistri'] && this.state.polizza.sinistri.label === 'Si' ?
                                            <div hidden className="row p-2">
                                                Il professionista dichiara che negli ultimi due anni non ha ricevuto richieste di risarcimento per errore professionale. Dichiara, inoltre, di non essere a conoscenza di circostanze suscettibili di causare richieste di risarcimento.
                                            </div>
                                            : <></>
                                        }


                                        { this.state.polizza['sinistri'] && this.state.polizza.sinistri.label === 'Si' ?
                                            <div className="row">
                                                <div className={isCurrentMobileDevice() ? null : 'col-7'}>
                                                    <GenericInput isDisabled key={'sinistriRicevutoLiberatoriaCompagnia'} id="sinistriRicevutoLiberatoriaCompagnia" label="Il professionista dichiara di aver ricevuto la liberatoria dalla compagnia" placeholder='Si / No'
                                                        currentStep={tabNumber}
                                                        defaultValue={(this.state.polizza.sinistriRicevutoLiberatoriaCompagnia) ? this.state.polizza.sinistriRicevutoLiberatoriaCompagnia.label : '-'}
                                                    />

                                                </div>
                                            </div> : null
                                            ?
                                            ( this.state.polizza['sinistri'] && this.state.polizza['sinistri'].label === 'No' ) :
                                                <div className="row" hidden>
                                                    <div className={isCurrentMobileDevice() ? null : 'col-7'}>
                                                        <GenericInput isDisabled key={'sinistriRicevutoLiberatoriaCompagnia'} id="sinistriRicevutoLiberatoriaCompagnia" label="Il professionista dichiara di aver ricevuto la liberatoria dalla compagnia" placeholder='Si / No'
                                                            currentStep={tabNumber}
                                                            defaultValue={(this.state.polizza['sinistriRicevutoLiberatoriaCompagnia']) ? this.state.polizza['sinistriRicevutoLiberatoriaCompagnia'] : null}
                                                        />
                                                    </div>

                                                </div>
                                        }
                                        <div style={{paddingTop:'20px', marginBottom:'30px'}}>
                                            <GenericCheckbox id="haLettoCircostanza" key={'haLettoCircostanza'} isChecked={(this.state.polizza['haLettoCircostanza']) ? this.state.polizza['haLettoCircostanza'] : null}
                                                label={"* Per circostanza si intende qualsiasi manifestazione dell'intenzione di avanzare una richiesta di risarcimento nei confronti dell'Assicurato; qualsiasi rilievo o contestazione diretti, scritti, espressi, riguardanti la condotta dell'Assicurato, da cui possa trarne origine una richiesta di risarcimento; qualsiasi atto o fatto di cui l'Assicurato sia a conoscenza e che potrebbe ragionevolmente dare luogo a una richiesta di risarcimento nei suoi confronti"}
                                                isDisabled={true}/>
                                        </div>
                                    </>
                                    }

                                    <div style={{paddingTop:'20px', marginBottom:'30px'}}>
                                        <label><b>Privacy</b></label>
                                        <GenericCheckbox
                                            id="privacy" key={'privacy'}
                                            isChecked={(this.state.polizza['privacy']) ? this.state.polizza['privacy'] : null}
                                            label={TestiLabel.testo_privacy} isDisabled={true}/>
                                        <br/>

                                        <label><b>Normativa di riferimento</b></label>
                                        <GenericCheckbox
                                            id="haLettoNormativa"
                                            key={'haLettoNormativa'}
                                            isChecked={this.state.polizza['haLettoNormativa']===1}
                                            label={TestiLabel.testo_presavisione_dpr}
                                            isDisabled={true}/>
                                        <a href={AppCostants.PUBLIC_SHARE+"/polizze/DPR_137_7_AGOSTO_2012.pdf"} target="_blank" className="link-style">
                                            Scarica l'allegato della normativa
                                        </a>
                                        <br/><br/>

                                        <label><b>Vademecum e Linee Guida</b></label>
                                        <GenericCheckbox id="haLettoVademecum" key={'haLettoVademecum'}
                                            isChecked={this.state.polizza['haLettoVademecum']===1}
                                            label={TestiLabel.testo_vademecum}
                                            value={this.state.polizza['haLettoVademecum']}
                                            isDisabled={true}/>
                                        <br />
                                    </div>
                                </div>
                            }></GenericCard>
                        </Form>

                        <GenericModal
                            title={this.state.modalTitle}
                            text={this.state.modalText}
                            modalOpen={this.state.modalOpen}
                            closeButton={this.closeModal}
                        />
                    </div>
                : <GenericSpinner/>}
                </div>
        )
    }
}

export declare interface GeneralFormDatiInformativiProps {
    polizza?: any;
    idPolizza?: any;
    idSoggetto?: any;
    messaggio?: any;
}
