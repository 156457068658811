/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-direct-mutation-state */
import { FormGroup, Input, Label } from 'design-react-kit'
import React from 'react'
import GenericCard from '../../../components/GenericCard'
import GenericCheckbox from '../../../components/GenericCheckbox'
import GenericUploadFront from '../../../components/GenericUpload'
import GenericInput from '../../../components/GenericInput'
import GenericSelect from '../../../components/GenericSelect'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getPolizzaById } from 'src/processes/assicurazioni/Assicurazione'
import { getComuniByProvinciaMsga } from 'src/processes/Comuni'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import { TestiLabel } from 'src/processes/assicurazioni/CostantiPolizze'
import { AppUtils as U } from 'src/app/utils/FormUtils'
import ListaAllegatiAssicurazioni from 'src/backoffice/app/components/Assicurazioni/ListaAllegatiAssicurazioni'
import { AppCostants } from 'src/app/utils/AppConstant'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { getStudiAssociatiByIdSoggetto } from 'src/processes/StudioAssociato'
import { goTo } from 'src/utilities/utility'
import GenericButton from 'src/frontend/app/components/GenericButton'
import { PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO } from 'src/app/utils/RoutesConstants'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const yes_no_options = [
  { label: 'Si', value: 'Si' },
  { label: 'No', value: 'No' },
]

const only_yes_generic_input = 'Si'
const only_no_generic_input = 'No'

export default class DatiInformativi extends React.Component<DatiInformativiProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: props.activeTab,
      tabClick: props.tabClick,
      prevData: props.prevData,
      prevClicked: props.prevClicked,
      polizza: props.polizza,
      visualizzaFlag: props.visualizzaFlag,
      isRequired: props.isRequired,
      modificaDatiFlag: props.modificaDatiFlag,
      idAllegato: props.idAllegato,
      prevFileMPUploaded: props.prevFileMPUploaded,
      getSelectValues: props.getSelectValues,
      errors: {},
      isLoaded: false,
      studioAssociatoFlag: false,
      congedoMaternitaPaternita: false,
      studioPresente: false,
      fileExists: false,
      message: '',
      utenteSTP: false,
    }
  }

  async componentDidMount(): Promise<void> {
    //verifico se l'utente è STP
    if (TokenStore.getInstance().isCurrentAccountSTP() === true) {
      this.setState({ utenteSTP: true })
    } else {
      this.setState({ utenteSTP: false })
    }

    let polizza = this.state.polizza
    this.setState({ polizza: polizza })
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //if(!this.props.visualizzaFlag) {
    let idSoggetto = TokenStore.getInstance().getCurrentAccount().idSoggetto
    this.setState({ idSoggetto: idSoggetto })
    await getStudiAssociatiByIdSoggetto(idSoggetto)
      .then(async (responseStudi) => {
        let listaStudi: any = []
        responseStudi.map(async (studio) => {
          let obj = {
            label: 'CF/P.IVA ' + studio.cfPiva + ' - ' + studio.ragioneSociale,
            value: studio.idStudioAssociato,
          }
          await listaStudi.push(obj)
        })
        await this.setState({ listaStudiAssociati: listaStudi })
      })
      .catch((err) => {
        this.setState({ listaStudiAssociati: [] })
      })
    if (this.props.storicoFlag) {
      polizza = this.props.data
      this.setState({ polizza: this.props.data })
      polizza.operaStudioAssociato = U.dn(polizza.operaStudioAssociato)
      polizza.idStudioAssociato = false
      if (this.state.listaStudiAssociati !== undefined) {
        let studioFound = this.state.listaStudiAssociati.find((studio) => studio.value === polizza.idStudioAssociato)
        polizza.idStudioAssociato = studioFound
      }

      polizza.altrePolizze = U.dn(polizza.altrePolizze)
      polizza.maternita = U.dn(polizza.maternita)
      polizza.paternita = U.dn(polizza.paternita)
      polizza.sinistri = U.dn(polizza.sinistri)
      polizza.sinistriRicevutoLiberatoriaCompagnia = U.dn(polizza.sinistriRicevutoLiberatoriaCompagnia)
      polizza.danniPatrimoniali = U.dn(polizza.danniPatrimoniali)
      polizza.retroattivitaIllimitata = U.dn(polizza.retroattivitaIllimitata)
      polizza.coperturaPostuma = U.dn(polizza.coperturaPostuma)
      if (typeof polizza.categoriaCalculated !== 'object')
        polizza.underTreAnniPrimaIscrizione = { label: polizza.categoriaCalculated === 2 ? 'Si' : 'No', value: polizza.categoriaCalculated === 2 ? 'Si' : 'No' }
      if (typeof polizza.idCategoriaDue !== 'object')
        polizza.idCategoriaDue = { label: polizza.categoria === 2 ? 'Si' : 'No', value: polizza.categoria === 2 ? 'Si' : 'No' }
      if (polizza.categoriaCalculated === 2) polizza.idCategoriaDue = { label: 'Si', value: 'Si' }
      if (typeof polizza.fileMaternitaPaternita) polizza.fileMaternitaPaternita = this.state.polizza.fileMaternitaPaternita
      await this.setState({ polizza: polizza })
      this.props.getSelectedValue(this.props.data, 'polizza')
    }

    if (this.props.data && !this.props.storicoFlag) {
      let id
      if (this.props.data) id = this.props.data.id
      if (this.props.prevData) id = this.props.prevData.id
      await getPolizzaById(id).then(async (response: any) => {
        this.setState({ polizza: response })
        if (response.assicurazioneAllegati) {
          let allegati = response.assicurazioneAllegati.sort((p1, p2) =>
            p1.dataInserimento > p2.dataInserimento && p1.tipologiaAllegato === 'polizze_dichiarazione_maternita_paternita'
              ? -1
              : p1.dataInserimento < p2.dataInserimento && p1.tipologiaAllegato === 'polizze_dichiarazione_maternita_paternita'
              ? 1
              : 0
          )
          allegati.map((fileAllegato) => {
            if (allegati !== undefined && allegati.length > 0 && fileAllegato.tipologiaAllegato === 'polizze_dichiarazione_maternita_paternita') {
              this.setState({ fileExists: true })
            }
          })
        }

        //casting da numerale a dropdown
        response.operaStudioAssociato = U.dn(response.operaStudioAssociato)
        let studioFound = this.state.listaStudiAssociati.find((studio) => studio.value === polizza.idStudioAssociato)
        response.idStudioAssociato = studioFound
        response.altrePolizze = U.dn(response.altrePolizze)
        response.maternita = U.dn(response.maternita)
        response.paternita = U.dn(response.paternita)
        response.sinistri = U.dn(response.sinistri)
        response.sinistriRicevutoLiberatoriaCompagnia = U.dn(response.sinistriRicevutoLiberatoriaCompagnia)
        response.danniPatrimoniali = U.dn(response.danniPatrimoniali)
        response.retroattivitaIllimitata = U.dn(response.retroattivitaIllimitata)
        response.coperturaPostuma = U.dn(response.coperturaPostuma)

        //TESTA COERENZA DATI
        if (typeof response.categoriaCalculated !== 'object')
          response.underTreAnniPrimaIscrizione = {
            label: response.categoriaCalculated === 2 ? 'Si' : 'No',
            value: response.categoriaCalculated === 2 ? 'Si' : 'No',
          }
        if (typeof response.idCategoriaDue !== 'object')
          response.idCategoriaDue = { label: response.categoria === 2 ? 'Si' : 'No', value: response.categoria === 2 ? 'Si' : 'No' }
        if (response.categoriaCalculated === 2) response.idCategoriaDue = { label: 'Si', value: 'Si' }
        if (typeof response.fileMaternitaPaternita) response.fileMaternitaPaternita = this.state.polizza.fileMaternitaPaternita
        await this.setState({ polizza: response })
        this.props.getSelectedValue(this.props.data, 'polizza')
      })
    }

    await this.setState({ isLoaded: true })
  }

  //setta l'allegato logicamente cancellato
  //finchè non confermo al next successivo
  //l'allegato non viene effettivamente rimosso dal db
  rimuoviAllegato = async (idAllegato?: any) => {
    //;
    this.state.polizza.assicurazioneAllegati.filter((a) => a.idAllegato === idAllegato).map((a) => (a.cancellato = 1))
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  showModal = async (booleanValue, title, text) => {
    await this.setState({
      modalOpen: booleanValue,
      modalTitle: title,
      modalText: text,
      isLoaded: true,
    })
  }

  resetFileMaternitaPaternita = (polizza: any) => {
    polizza.fileMaternitaPaternita = []
    this.setState({ polizza: polizza, prevFileMPUploaded: false })
    this.props.getSelectedValue(polizza, 'polizza')
  }

  render() {
    const { tabNumber } = this.props
    const { listaStudiAssociati } = this.state

    const getDatiUploadFile = (fileData) => {
      this.state.polizza['fileMaternitaPaternita'] = [fileData]
      this.props.getSelectedValue(this.state.polizza, 'polizza')
    }

    /** Funzione per aggiornare i campi del form */
    const setDataItem = (e: any, type: any, field: any, index?: any) => {
      let polizza = this.state.polizza
      if (type === 'select') {
        polizza[field] = { label: e.label, value: e.value }
        //se il campo è categoria 2 setto anche maternita e partenità a zero
        if (field === 'idCategoriaDue' && (e.value === 0 || e.value === 'No')) {
          polizza['maternita'] = { label: e.label, value: e.value }
          polizza['paternita'] = { label: e.label, value: e.value }
        }

        if (field === 'maternita' && (e.value === 0 || e.value === 'Si')) {
          polizza['paternita'] = { label: 'No', value: 'No' }
        }

        if (field === 'paternita' && (e.value === 0 || e.value === 'Si')) {
          polizza['maternita'] = { label: 'No', value: 'No' }
        }
      } else if (type === 'checkbox') {
        polizza[field] = e.target.checked
      } else if (field === 'fileMaternitaPaternita') {
        polizza['fileMaternitaPaternita'][0] = e.target.value
      } else if (field === 'sinistri' && e.value === 'No') {
        polizza['"sinistriRicevutoLiberatoriaCompagnia"'] = undefined
      } else polizza[field] = e.target.value

      //studio presente???? ma non lo setta a livello di stato, o a livello di polizza
      if (field === 'operaStudioAssociato' && e.label === 'Si') {
        this.setState({ studioPresente: true })
      } else this.setState({ studioPresente: false })

      //c'è un campo provincia dello studio?? la setta?...vedere sull'STP
      if (field === 'stuAssProv') {
        this.setState({ provinciaSelected: false })
        this.state.polizza['stuAssCitta'] = undefined
        getComuniByProvinciaMsga(polizza['stuAssProv'].label).then(async (comuni) => {
          this.setState({ listaComuni: comuni, isLoaded: true, provinciaSelected: true })
        })
      }
      this.setState({ polizza: polizza })

      //setta congedo maternita e paternita true else false
      //questo non dovrebbe settarlo sulla polizza
      if (field === 'idCategoriaDue' && e.label === 'Si') {
        this.setState({ congedoMaternitaPaternita: true })
      } else this.setState({ congedoMaternitaPaternita: false })

      //maternità e paternita non dovrebbe settarlo nella polizza???
      if (
        (this.state.polizza['maternita'] && this.state.polizza['maternita'].label === 'Si') ||
        (this.state.polizza['paternita'] && this.state.polizza['paternita'].label === 'Si')
      )
        this.setState({ congedoMaternitaPaternita: true })
      else this.setState({ congedoMaternitaPaternita: false })

      //opera studio associato ma dove lo setta??? non dovrebbe settarlo sulla polizza???
      if (this.state.polizza['operaStudioAssociato'] && this.state.polizza['operaStudioAssociato'].label === 'Si') this.setState({ studioAssociatoFlag: true })
      else this.setState({ studioAssociatoFlag: false })

      //aggiornamento della polizza al componente padre
      this.props.getSelectedValue(polizza, 'polizza')
    }

    /********************************* Form Polizza ************************************/
    const formPolizza = [
      <div id="formPolizza" hidden={!this.state.polizza} key={'formPolizza'}>
        <GenericCard
          key={'operaStudioAssociatoCard'}
          cardHeader="Studio Associato"
          cardBody={
            <div>
              <div className="row">
                <div hidden={this.props.visualizzaFlag} className={isCurrentMobileDevice() ? null : 'col-5'}>
                  <GenericSelect
                    key={'operaStudioAssociato'}
                    elementId="operaStudioAssociato"
                    onChange={(e) => setDataItem(e, 'select', 'operaStudioAssociato')}
                    label="Selezione Studi Associati"
                    placeholder="Si / No"
                    defaultOptions={yes_no_options}
                    currentStep={tabNumber}
                    isRequired={false}
                    defaultValue={this.state.polizza['operaStudioAssociato'] ? this.state.polizza['operaStudioAssociato'] : null}
                  />

                  <div hidden={!this.props.visualizzaFlag} className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      isDisabled
                      id="sedeLegaleStudio"
                      name={'sedeLegaleStudio'}
                      defaultValue={this.state.polizza['sedeLegaleStudio'] ? this.state.polizza['sedeLegaleStudio'].label : 'No'}
                      onChange={(e) => setDataItem(e, 'select', 'sedeLegaleStudio')}
                      label="Sede legale diversa dalla residenza? *"
                      errorMessageAlert={this.props.message}
                      currentStep={tabNumber}
                      placeholder="Si / No"
                    />
                  </div>
                </div>
                <div
                  hidden={!TokenStore.getInstance().isCurrentAccountIscrittoOrdine() || this.state.polizza['operaStudioAssociato'].label !== 'Si'}
                  className={isCurrentMobileDevice() ? null : 'col-5'}
                >
                  <GenericSelect
                    key={'idStudioAssociato'}
                    elementId="idStudioAssociato"
                    onChange={(e) => setDataItem(e, 'select', 'idStudioAssociato')}
                    label="Studio Associato"
                    placeholder="Seleziona"
                    defaultOptions={listaStudiAssociati}
                    currentStep={tabNumber}
                    isRequired={false}
                    defaultValue={this.state.polizza['idStudioAssociato'] ? this.state.polizza['idStudioAssociato'] : null}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  hidden={!TokenStore.getInstance().isCurrentAccountIscrittoOrdine() || this.state.polizza['operaStudioAssociato'].label !== 'Si'}
                  className={isCurrentMobileDevice() ? null : 'col-3'}
                >
                  <GenericButton size="xs" type="button" color="primary" label="Aggiungi Nuovo" onClickEvent={() => goTo(PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO)} />
                </div>
              </div>
            </div>
          }
        ></GenericCard>

        <GenericCard
          key={'altrePolizze'}
          cardHeader="Altre Polizze"
          cardBody={
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                <GenericSelect
                  key={'altrePolizze'}
                  id="altrePolizze"
                  label="Possiede un’altra polizza assicurativa? *"
                  placeholder="Si / No"
                  errorMessageAlert={this.props.message}
                  currentStep={tabNumber}
                  defaultOptions={yes_no_options}
                  isRequired={!this.state.polizza['altrePolizze']}
                  defaultValue={this.state.polizza['altrePolizze'] ? this.state.polizza['altrePolizze'] : null}
                  onChange={(e) => setDataItem(e, 'select', 'altrePolizze')}
                />
              </div>
            </div>
          }
        ></GenericCard>

        {/* per utente STP nascondo la categoria due */}
        {this.state.utenteSTP === true && (
          <>
            <Input type={'hidden'} id={'idCategoriaDue'} defaultValue={only_no_generic_input} />
            <Input type={'hidden'} id={'maternita'} defaultValue={only_no_generic_input} />
            <Input type={'hidden'} id={'paternita'} defaultValue={only_no_generic_input} />
          </>
        )}

        {/* per utente STP visualizzo la categoria due */}
        {this.state.utenteSTP === false && (
          <>
            <GenericCard
              key={'categoriaDue'}
              cardHeader="Appartenenza alla Categoria 2"
              cardBody={
                <div>
                  <div className="row">
                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                      {this.state.polizza.underTreAnniPrimaIscrizione && this.state.polizza.underTreAnniPrimaIscrizione.label === 'Si' ? (
                        <>
                          {/* se sono sotto i 35 anni sono categoria due ma devo poter anche scegliere se sono in maternità o paternità */}
                          <GenericInput
                            key={'idCategoriaDue'}
                            id="idCategoriaDue"
                            label="Appartenenza alla categoria 2? *"
                            placeholder="Si / No"
                            isDisabled
                            defaultValue={only_yes_generic_input}
                          />
                          <GenericSelect
                            key={'maternita'}
                            elementId="maternita"
                            onChange={(e) => setDataItem(e, 'select', 'maternita')}
                            label="Maternità "
                            placeholder="Si / No"
                            defaultOptions={yes_no_options}
                            errorMessageAlert={this.props.message}
                            currentStep={tabNumber}
                            isRequired={false}
                            defaultValue={this.state.polizza['maternita'] ? this.state.polizza['maternita'] : null}
                            value={this.state.polizza['maternita']}
                          />

                          <GenericSelect
                            key={'paternita'}
                            elementId="paternita"
                            onChange={(e) => setDataItem(e, 'select', 'paternita')}
                            label="Paternità "
                            placeholder="Si / No"
                            defaultOptions={yes_no_options}
                            errorMessageAlert={this.props.message}
                            currentStep={tabNumber}
                            isRequired={false}
                            defaultValue={this.state.polizza['paternita'] ? this.state.polizza['paternita'] : null}
                            value={this.state.polizza['paternita']}
                          />
                        </>
                      ) : (
                        <GenericSelect
                          key={'idCategoriaDue'}
                          elementId="idCategoriaDue"
                          onChange={(e) => setDataItem(e, 'select', 'idCategoriaDue')}
                          label="Appartenenza alla categoria 2? *"
                          placeholder="Si / No"
                          defaultOptions={yes_no_options}
                          defaultValue={
                            this.state.polizza.underTreAnniPrimaIscrizione && this.state.polizza.underTreAnniPrimaIscrizione.label === 'Si'
                              ? this.state.polizza.idCategoriaDue
                              : this.state.polizza['idCategoriaDue']
                              ? this.state.polizza['idCategoriaDue']
                              : 'No'
                          }
                        />
                      )}
                    </div>
                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                      <GenericInput
                        isDisabled
                        key={'underTreAnniPrimaIscrizione'}
                        id="underTreAnniPrimaIscrizione"
                        name={'underTreAnniPrimaIscrizione'}
                        onChange={(e) => setDataItem(e, 'select', 'underTreAnniPrimaIscrizione')}
                        label="Sotto i 35 anni e con meno di tre anni solari dalla prima iscrizione? **"
                        placeholder="No"
                        defaultValue={this.state.polizza.underTreAnniPrimaIscrizione ? this.state.polizza.underTreAnniPrimaIscrizione.label : 'No'}
                      />
                    </div>
                  </div>
                  {this.state.polizza['idCategoriaDue'] &&
                  this.state.polizza['idCategoriaDue'].label === 'Si' &&
                  this.state.polizza.underTreAnniPrimaIscrizione &&
                  this.state.polizza.underTreAnniPrimaIscrizione.label !== 'Si' ? (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericSelect
                          key={'maternita'}
                          elementId="maternita"
                          onChange={(e) => setDataItem(e, 'select', 'maternita')}
                          label="Maternità "
                          placeholder="Si / No"
                          defaultOptions={yes_no_options}
                          errorMessageAlert={this.props.message}
                          currentStep={tabNumber}
                          isRequired={this.state.polizza['maternita'] ? false : true}
                          defaultValue={this.state.polizza['maternita'] ? this.state.polizza['maternita'] : null}
                          value={this.state.polizza['maternita']}
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericSelect
                          key={'paternita'}
                          elementId="paternita"
                          onChange={(e) => setDataItem(e, 'select', 'paternita')}
                          label="Paternità "
                          placeholder="Si / No"
                          defaultOptions={yes_no_options}
                          errorMessageAlert={this.props.message}
                          currentStep={tabNumber}
                          isRequired={this.state.polizza['paternita'] ? false : true}
                          defaultValue={this.state.polizza['paternita'] ? this.state.polizza['paternita'] : null}
                          value={this.state.polizza['paternita']}
                        />
                      </div>
                    </div>
                  ) : null ? (
                    this.state.polizza['idCategoriaDue'] && this.state.polizza['idCategoriaDue'].label === 'No'
                  ) : (
                    <div className="row" hidden>
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericSelect
                          key={'maternita'}
                          elementId="maternita"
                          onChange={(e) => setDataItem(e, 'select', 'maternita')}
                          label="Maternità "
                          placeholder="Si / No"
                          defaultOptions={yes_no_options}
                          isRequired
                          defaultValue={this.state.polizza['maternita'] ? this.state.polizza['maternita'] : null}
                          value={this.state.polizza['maternita']}
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericSelect
                          key={'paternita'}
                          elementId="paternita"
                          onChange={(e) => setDataItem(e, 'select', 'paternita')}
                          label="Paternità "
                          placeholder="Si / No"
                          defaultOptions={yes_no_options}
                          isRequired
                          defaultValue={this.state.polizza['paternita'] ? this.state.polizza['paternita'] : null}
                          value={this.state.polizza['paternita']}
                        />
                      </div>
                    </div>
                  )}

                  <div className="row" hidden={(this.state.polizza['idCategoriaDue'] && this.state.polizza['idCategoriaDue'].label === 'No') === true}>
                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                      <Label key="foto">Certificato Maternità / Paternità (* obbligatorio se si sceglie maternità o paternità) </Label>
                    </div>
                    <div className="col-7">
                      <FormGroup label={'fileMaternitaPaternita'}>
                        {this.state.fileExists ? (
                          <FormGroup label={'fileMaternitaPaternita'}>
                            <div className="row">
                              <GenericUploadFront
                                datiAllegato={getDatiUploadFile}
                                id={'fileMaternitaPaternita'}
                                isRequired={this.state.prevFileMPUploaded === true ? false : true}
                                acceptedExtensionFile={['pdf']}
                                tipologiaAllegato={'polizze_dichiarazione_maternita_paternita'}
                                key="fileMaternitaPaternita"
                              />
                            </div>
                          </FormGroup>
                        ) : (
                          <FormGroup label={'fileMaternitaPaternita'}>
                            <GenericUploadFront
                              datiAllegato={getDatiUploadFile}
                              id={'fileMaternitaPaternita'}
                              isRequired={this.state.prevFileMPUploaded === true ? false : true}
                              acceptedExtensionFile={['pdf']}
                              tipologiaAllegato={'polizze_dichiarazione_maternita_paternita'}
                              key="fileMaternitaPaternita"
                            />
                          </FormGroup>
                        )}
                      </FormGroup>
                    </div>

                    <div className="row" style={{ marginTop: '-10px' }}>
                      <ListaAllegatiAssicurazioni
                        polizza={this.state.polizza}
                        tipologiaAllegato={'polizze_dichiarazione_maternita_paternita'}
                        visualizzaFlag={this.props.visualizzaFlag}
                        resetFile={this.resetFileMaternitaPaternita}
                        nomeAllegato={'fileMaternitaPaternita'}
                        getSelectedValue={this.props.getSelectedValue}
                      />
                    </div>
                  </div>
                </div>
              }
            ></GenericCard>
          </>
        )}

        <GenericCard
          key={'dichiarazioni'}
          cardHeader="Dichiarazioni"
          cardBody={
            <div>
              {this.state.polizza?.tipoRichiesta === 'A' && (
                <>
                  <div className="row">
                    <Label>
                      Il professionista dichiara che negli ultimi due anni ha ricevuto richieste di risarcimento per errore professionale e/o di essere a
                      conoscenza di circostanze suscettibili di causare richieste di risarcimento.
                    </Label>
                    <div className="col-5">
                      <GenericSelect
                        key={'sinistri'}
                        elementId="sinistri"
                        onChange={(e) => setDataItem(e, 'select', 'sinistri')}
                        label="Dichiarazione richieste sinistro *"
                        placeholder="Si / No"
                        defaultOptions={yes_no_options}
                        isRequired={!this.state.polizza['sinistri']}
                        errorMessageAlert={this.props.message}
                        currentStep={tabNumber}
                        defaultValue={this.state.polizza['sinistri'] ? this.state.polizza['sinistri'] : null}
                      />
                    </div>
                  </div>

                  {this.state.polizza['sinistri'] && this.state.polizza['sinistri'].label === 'Si' ? (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericSelect
                          key={'sinistriRicevutoLiberatoriaCompagnia'}
                          elementId="sinistriRicevutoLiberatoriaCompagnia"
                          onChange={(e) => setDataItem(e, 'select', 'sinistriRicevutoLiberatoriaCompagnia')}
                          label="Il professionista dichiara di aver ricevuto la liberatoria dalla compagnia"
                          placeholder="Si / No"
                          defaultOptions={yes_no_options}
                          errorMessageAlert={this.props.message}
                          currentStep={tabNumber}
                          isRequired={
                            this.state.polizza['sinistri'] &&
                            this.state.polizza['sinistri'].label === 'Si' &&
                            !this.state.polizza['sinistriRicevutoLiberatoriaCompagnia']
                          }
                          defaultValue={
                            this.state.polizza['sinistriRicevutoLiberatoriaCompagnia'] ? this.state.polizza['sinistriRicevutoLiberatoriaCompagnia'] : null
                          }
                        />
                      </div>
                      <div className="row">
                        <Label>DICHIARAZIONE SINISTRO / CIRCOSTANZA PREGRESSA</Label>
                        <Label>
                          {"Contattare il broker per la valutazione e validazione dell'assicurazione. E' necessario scaricare il modulo sinistri da questo "}
                          <a href={AppCostants.PUBLIC_SHARE + '/polizze/16_Modulo%20sinistri_circostanze_9.11.pdf'} target="_blank" className="link-style">
                            {' '}
                            link{' '}
                          </a>{' '}
                          e dopo averlo compilato spedirlo ad Aon all'indirizzo PEC roma01@pec.aon.it
                        </Label>
                        <br />
                      </div>
                    </div>
                  ) : null}

                  <br />

                  <Label>
                    <b>Dichiarazione di comprensione</b>
                  </Label>
                  <GenericCheckbox
                    title="Dichiarazione di comprensione"
                    id="haLettoCircostanza"
                    key={'haLettoCircostanza'}
                    isRequired={this.state.polizza['haLettoCircostanza'] === 1 || this.state.polizza['haLettoCircostanza'] === true ? false : true}
                    defaultChecked={this.state.polizza['haLettoCircostanza'] ? this.state.polizza['haLettoCircostanza'] : null}
                    onChange={(e) => setDataItem(e, 'checkbox', 'haLettoCircostanza')}
                    errorMessageAlert={this.props.message}
                    currentStep={tabNumber}
                    label={TestiLabel.testo_dichiarazione_circostanza}
                    isDisabled={false}
                  />
                  <br></br>
                </>
              )}

              <div style={{ paddingTop: '20px', marginBottom: '30px' }}>
                <Label>
                  <b>Privacy</b>
                </Label>
                <GenericCheckbox
                  id="privacy"
                  key={'privacy'}
                  title="Privacy"
                  isRequired={this.state.polizza['privacy'] === 1 || this.state.polizza['privacy'] === true ? false : true}
                  defaultChecked={this.state.polizza['privacy'] ? this.state.polizza['privacy'] : null}
                  onChange={(e) => setDataItem(e, 'checkbox', 'privacy')}
                  errorMessageAlert={this.props.message}
                  currentStep={tabNumber}
                  label={TestiLabel.testo_privacy}
                  isDisabled={false}
                />

                <br />

                <Label>
                  <b>Normativa di riferimento</b>
                </Label>
                <GenericCheckbox
                  id="haLettoNormativa"
                  key={'haLettoNormativa'}
                  title="Normativa di riferimento"
                  isRequired={this.state.polizza['haLettoNormativa'] === 1 || this.state.polizza['haLettoNormativa'] === true ? false : true}
                  defaultChecked={this.state.polizza['haLettoNormativa'] ? this.state.polizza['haLettoNormativa'] : null}
                  onChange={(e) => setDataItem(e, 'checkbox', 'haLettoNormativa')}
                  errorMessageAlert={this.props.message}
                  currentStep={tabNumber}
                  label={TestiLabel.testo_presavisione_dpr}
                  isDisabled={false}
                />

                <a
                  href={AppCostants.PUBLIC_SHARE + '/polizze/3_Regolamento%20Polizza%20collettiva%20CONAF_agg.%202021_9.2.3.1.pdf'}
                  target="_blank"
                  className="link-style"
                >
                  Scarica l'allegato della normativa
                </a>

                <br />
                <br />

                <Label>
                  <b>Vademecum e Linee Guida</b>
                </Label>

                <GenericCheckbox
                  id="haLettoVademecum"
                  key={'haLettoVademecum'}
                  title="Vademecum e Linee Guida"
                  isRequired={this.state.polizza['haLettoVademecum'] === 1 || this.state.polizza['haLettoVademecum'] === true ? false : true}
                  defaultChecked={this.state.polizza['haLettoVademecum'] ? this.state.polizza['haLettoVademecum'] : null}
                  onChange={(e) => setDataItem(e, 'checkbox', 'haLettoVademecum')}
                  errorMessageAlert={this.props.message}
                  currentStep={tabNumber}
                  label={TestiLabel.testo_vademecum}
                  isDisabled={false}
                />
                <a
                  href={AppCostants.PUBLIC_SHARE + '/polizze/1_VADEMECUM_ASSICURATI_CONAF_IT00024030EO20A_09_08_24.pdf'}
                  target="_blank"
                  className="link-style"
                >
                  Scarica l'allegato del Vademecum
                </a>
                <br />
                <a
                  href={AppCostants.PUBLIC_SHARE + '/polizze/2_Linee_guida_Polizza_RC_professionale_collettiva_CONAF_2024.pdf'}
                  target="_blank"
                  className="link-style"
                >
                  Scarica l'allegato delle Linee Guida
                </a>
                <br />
              </div>
            </div>
          }
        ></GenericCard>

        {this.state.polizza?.tipoRichiesta !== 'A' && <></>}
      </div>,
    ]

    const formPolizzaVisualizza = [
      <div id="formPolizza" key={'formPolizzaVisualizza'} className="row" hidden={!this.state.polizza}>
        <GenericCard
          key={'studioAssociatoCard'}
          cardHeader="Studio Associato"
          cardBody={
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                <GenericInput
                  isDisabled
                  label="Selezione Studi Associati "
                  placeholder="Si / No"
                  defaultValue={this.state.polizza.operaStudioAssociato ? this.state.polizza.operaStudioAssociato.label : '-'}
                  currentStep={tabNumber}
                  isRequired={false}
                />
              </div>
              <div
                hidden={!TokenStore.getInstance().isCurrentAccountIscrittoOrdine() || this.state.polizza['operaStudioAssociato'].label !== 'Si'}
                className={isCurrentMobileDevice() ? null : 'col-5'}
              >
                <GenericInput
                  isDisabled
                  key={'idStudioAssociato'}
                  id="idStudioAssociato"
                  label="Studio Associato"
                  placeholder="Studio associato selezionato"
                  defaultValue={this.state.polizza['idStudioAssociato'] ? this.state.polizza['idStudioAssociato'].label : '-'}
                />
              </div>
            </div>
          }
        ></GenericCard>
        <GenericCard
          key={'altrePolizze'}
          cardHeader="Altre Polizze"
          cardBody={
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                <GenericInput
                  isDisabled
                  key={'altrePolizze'}
                  id="altrePolizze"
                  label="Possiede un’altra polizza assicurativa? *"
                  placeholder="Si / No"
                  currentStep={tabNumber}
                  defaultValue={this.state.polizza.altrePolizze ? this.state.polizza.altrePolizze.label : '-'}
                />
              </div>
            </div>
          }
        ></GenericCard>
        <GenericCard
          key={'categoriaDue'}
          cardHeader="Appartenenza alla Categoria 2"
          cardBody={
            <div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                  <GenericInput
                    isDisabled
                    label="Appartenenza alla categoria 2"
                    placeholder="Si / No"
                    defaultValue={this.state.polizza['idCategoriaDue'] ? this.state.polizza['idCategoriaDue'].label : '-'}
                    isRequired={false}
                    currentStep={tabNumber}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                  <GenericInput
                    isDisabled
                    key={'underTreAnniPrimaIscrizione'}
                    id="underTreAnniPrimaIscrizione"
                    name={'underTreAnniPrimaIscrizione'}
                    label="Meno di 35 anni e meno di 3 anni solari dalla prima iscrizione **"
                    placeholder="No"
                    defaultValue={this.state.polizza.underTreAnniPrimaIscrizione ? this.state.polizza.underTreAnniPrimaIscrizione.label : 'No'}
                    errorMessageAlert={this.props.message}
                    currentStep={tabNumber}
                  />
                </div>
              </div>

              {this.state.polizza['idCategoriaDue'] &&
              this.state.polizza['idCategoriaDue'].label === 'Si' &&
              (this.state.polizza['maternita'].label === 'Si' || this.state.polizza['paternita'].label === 'Si') ? (
                <>
                  <div className="row">
                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                      <GenericInput
                        isDisabled
                        label="Maternità "
                        placeholder="Si / No"
                        defaultValue={this.state.polizza['maternita'] ? this.state.polizza['maternita'].label : null}
                        errorMessageAlert={this.props.message}
                        currentStep={tabNumber}
                      />
                    </div>
                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                      <GenericInput
                        isDisabled
                        label="Paternità "
                        placeholder="Si / No"
                        defaultValue={this.state.polizza['paternita'] ? this.state.polizza['paternita'].label : null}
                        errorMessageAlert={this.props.message}
                        currentStep={tabNumber}
                      />
                    </div>
                  </div>
                  <div
                    className="row"
                    hidden={
                      this.state.polizza['idCategoriaDue'] && this.state.polizza['maternita'].label === 'No' && this.state.polizza['paternita'].label === 'No'
                    }
                  >
                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                      <Label key="foto">Certificato Maternità / Paternità</Label>
                    </div>

                    <div className="row">
                      <ListaAllegatiAssicurazioni
                        polizza={this.state.polizza}
                        nomeAllegato={'fileMaternitaPaternita'}
                        tipologiaAllegato={'polizze_dichiarazione_maternita_paternita'}
                        visualizzaFlag={this.props.visualizzaFlag}
                        resetFile={this.resetFileMaternitaPaternita}
                        note={false}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          }
        ></GenericCard>

        <GenericCard
          key={'dichiarazioni'}
          cardHeader="Dichiarazioni"
          cardBody={
            <div>
              {this.state.polizza?.tipoRichiesta === 'A' && (
                <>
                  <div className="row">
                    <Label>
                      Il professionista dichiara che negli ultimi due anni ha ricevuto richieste di risarcimento per errore professionale e/o di essere a
                      conoscenza di circostanze suscettibili di causare richieste di risarcimento.
                    </Label>
                    <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                      <GenericInput
                        isDisabled
                        key={'sinistri'}
                        id="sinistri"
                        label="Dichiarazione richieste sinistro *"
                        placeholder="Si / No"
                        defaultValue={this.state.polizza.sinistri ? this.state.polizza.sinistri.label : '-'}
                      />
                    </div>
                  </div>
                  {this.state.polizza['sinistri'] && this.state.polizza.sinistri.label === 'Si' ? (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericInput
                          isDisabled
                          key={'sinistriRicevutoLiberatoriaCompagnia'}
                          id="sinistriRicevutoLiberatoriaCompagnia"
                          onChange={(e) => setDataItem(e, 'select', 'sinistriRicevutoLiberatoriaCompagnia')}
                          label="Il professionista dichiara di aver ricevuto la liberatoria dalla compagnia"
                          placeholder="Si / No"
                          currentStep={tabNumber}
                          isRequired={
                            this.state.polizza.sinistri &&
                            this.state.polizza.sinistri.label === 'Si' &&
                            !this.state.polizza['sinistriRicevutoLiberatoriaCompagnia']
                          }
                          defaultValue={
                            this.state.polizza.sinistriRicevutoLiberatoriaCompagnia ? this.state.polizza.sinistriRicevutoLiberatoriaCompagnia.label : null
                          }
                        />
                      </div>
                    </div>
                  ) : null ? (
                    this.state.polizza['sinistri'] && this.state.polizza['sinistri'].label === 'No'
                  ) : (
                    <div className="row" hidden>
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericInput
                          isDisabled
                          key={'sinistriRicevutoLiberatoriaCompagnia'}
                          id="sinistriRicevutoLiberatoriaCompagnia"
                          label="Il professionista dichiara di aver ricevuto la liberatoria dalla compagnia"
                          placeholder="Si / No"
                          currentStep={tabNumber}
                          isRequired={
                            this.state.polizza['sinistri'] &&
                            this.state.polizza['sinistri'].label === 'Si' &&
                            !this.state.polizza['sinistriRicevutoLiberatoriaCompagnia']
                          }
                          defaultValue={
                            this.state.polizza['sinistriRicevutoLiberatoriaCompagnia'] ? this.state.polizza['sinistriRicevutoLiberatoriaCompagnia'] : null
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div style={{ paddingTop: '20px', marginBottom: '30px' }}>
                    <GenericCheckbox
                      id="haLettoCircostanza"
                      key={'haLettoCircostanza'}
                      defaultChecked={this.state.polizza['haLettoCircostanza'] ? this.state.polizza['haLettoCircostanza'] : null}
                      onChange={(e) => setDataItem(e, 'checkbox', 'haLettoCircostanza')}
                      label={
                        "* Per circostanza si intende qualsiasi manifestazione dell'intenzione di avanzare una richiesta di risarcimento nei confronti dell'Assicurato; qualsiasi rilievo o contestazione diretti, scritti, espressi, riguardanti la condotta dell'Assicurato, da cui possa trarne origine una richiesta di risarcimento; qualsiasi atto o fatto di cui l'Assicurato sia a conoscenza e che potrebbe ragionevolmente dare luogo a una richiesta di risarcimento nei suoi confronti"
                      }
                      isDisabled={true}
                      errorMessageAlert={this.props.message}
                      isRequired
                      currentStep={tabNumber}
                    />
                  </div>
                </>
              )}

              <Label>
                <b>Privacy</b>
              </Label>
              <GenericCheckbox
                id="privacy"
                key={'privacy'}
                defaultChecked={this.state.polizza['privacy'] ? this.state.polizza['privacy'] : null}
                onChange={(e) => setDataItem(e, 'checkbox', 'privacy')}
                label={TestiLabel.testo_privacy}
                isDisabled={true}
                errorMessageAlert={this.props.message}
                isRequired
                currentStep={tabNumber}
              />

              <br />

              <Label>
                <b>Normativa di riferimento</b>
              </Label>
              <GenericCheckbox
                id="haLettoNormativa"
                key={'haLettoNormativa'}
                isRequired={this.state.polizza['haLettoNormativa'] === 1 || this.state.polizza['haLettoNormativa'] === true ? false : true}
                defaultChecked={this.state.polizza['haLettoNormativa'] ? this.state.polizza['haLettoNormativa'] : null}
                onChange={(e) => setDataItem(e, 'checkbox', 'haLettoNormativa')}
                errorMessageAlert={this.props.message}
                currentStep={tabNumber}
                label={TestiLabel.testo_presavisione_dpr}
                isDisabled={true}
              />
              <a href={AppCostants.PUBLIC_SHARE + '/polizze/DPR_137_7_AGOSTO_2012.pdf'} target="_blank" className="link-style">
                Scarica l'allegato della normativa
              </a>
              <br />
              <br />

              <Label>
                <b>Vademecum e Linee Guida</b>
              </Label>

              <GenericCheckbox
                id="haLettoVademecum"
                key={'haLettoVademecum'}
                isRequired={this.state.polizza['haLettoVademecum'] === 1 || this.state.polizza['haLettoVademecum'] === true ? false : true}
                defaultChecked={this.state.polizza['haLettoVademecum'] ? this.state.polizza['haLettoVademecum'] : null}
                onChange={(e) => setDataItem(e, 'checkbox', 'haLettoVademecum')}
                errorMessageAlert={this.props.message}
                currentStep={tabNumber}
                label={TestiLabel.testo_vademecum}
                isDisabled={true}
              />

              <br />
            </div>
          }
        ></GenericCard>
      </div>,
    ]

    if (this.state.isLoaded)
      return (
        <div key={'page'}>
          {this.props.modificaDatiFlag ? formPolizza : this.props.visualizzaFlag ? formPolizzaVisualizza : formPolizza}
          <GenericModal title={this.state.modalTitle} text={this.state.modalText} modalOpen={this.state.modalOpen} closeButton={this.closeModal} />
        </div>
      )
    else
      return (
        <div key={'spinner'}>
          <GenericSpinner />
        </div>
      )
  }
}
export declare interface DatiInformativiProps {
  data?: any
  polizza?: any
  activeTab?: any
  tabClick?: any
  modificaDatiFlag?: any
  storicoFlag?: any
  visualizzaFlag?: any
  aumentoMassimaleFlag?: any
  polizzaInsColl?: any
  prevData?: any
  getSelectedValue?: any
  tabNumber?: any
  message?: any
  tabellaUno?: any
  selectedFile?: any
  datiAllegato?: any
  fileData?: any
  getDatiUploadFile?: any
  prevFileMPUploaded?: any
  idAllegato?: any
  isRequired?: any
  fileExists?: any
}
